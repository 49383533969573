import React, { FunctionComponent, useContext } from "react";
import { StyledSection, StyledSectionButton } from "../layout/Section";
import styled from "styled-components";
import { sortByAsc } from "../utils/arrayUtils";
import { HpLocation, Instruction } from "../data/hpTypes";
import { LocationContext } from "../location/LocationPicker";
import distance from "@turf/distance";
import { point } from "../location/locationUtils";
import { formatDistanceKm } from "../utils/distanceUtils";
import { simpleHash } from "../utils/simpleHash";
import { VAXXNZ } from "../consts";
import { getSearch, getTabPath, SeeMoreLink, VaxxTab } from "../layout/SeeMore";
import { useWalkInData, WalkInDataResult } from "./WalkInData";
import { ErrorMessage } from "../layout/ErrorMessage";
import { LoadingText } from "../layout/Loading";

const WalkInLink = styled.a`
  all: unset;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: none;
  background-color: white;
  font-family: inherit;
`;

const StyledWalkInItem = styled(StyledSectionButton)`
  & > a {
    all: unset;
  }

  display: flex;
  justify-content: space-between;
  width: 100%;
  border: none;
  background-color: white;
  font-family: inherit;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;

  img {
    height: 16px;
    align-self: center;
  }
`;

const Bullet = styled.span`
  &:before {
    content: "•";
    opacity: 0.6;
    display: inline-block;
    margin: 0 0.2rem;
  }
`;

const WALK_IN_RADIUS = 10;

export const WalkInSection: FunctionComponent = () => {
  const closeWalkIns = useCloseWalkIns(WALK_IN_RADIUS);

  if (
    "error" in closeWalkIns ||
    ("ok" in closeWalkIns && closeWalkIns.ok.length === 0)
  ) {
    return null;
  }

  return (
    <StyledSection>
      <SeeMoreLink radiusKm={WALK_IN_RADIUS} noStyle tab={VaxxTab.walkIn}>
        <WalkInButton
          walkInCount={
            "loading" in closeWalkIns ? null : closeWalkIns.ok.length
          }
        />
      </SeeMoreLink>
    </StyledSection>
  );
};

const StyledWalkInButton = styled(StyledSectionButton)`
  display: flex;
  align-items: flex-start;
  text-decoration: none;
  width: 100%;

  h3 {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 600;
    color: ${(props) => props.theme.colors.text.secondary};
  }

  div p {
    color: ${(props) => props.theme.colors.text.tertiary};
    margin: 0;
    font-size: 0.85rem;
  }

  & > p {
    color: ${(props) => props.theme.colors.text.accent};
    margin-left: auto;
    align-self: center;
  }

  &:hover > p {
    text-decoration: underline;
  }
  @media screen and (max-width: 500px) {
    flex-direction: column;
    & > p {
      margin-top: 0.5rem;
      width: 100%;
      text-align: left;
    }
  }
`;

interface WalkInButtonProps {
  walkInCount: number | null;
}

const WalkInButton: FunctionComponent<WalkInButtonProps> = ({
  walkInCount,
}) => {
  return (
    <StyledWalkInButton>
      <div>
        <h3>Walk-In/Drive Thru Vaccinations</h3>
        <p>
          No booking required, but there may be a queue.
        </p>
      </div>
      <p>
        {walkInCount === null ? (
          <LoadingText len={16} />
        ) : (
          `See ${walkInCount} centres...`
        )}
      </p>
    </StyledWalkInButton>
  );
};

export const WalkIns: FunctionComponent = () => {
  const hpData = useWalkInData();

  if ("ok" in hpData) {
    return <WalkInItem hpLocations={hpData.ok} />;
  } else if ("loading" in hpData) {
    return <LoadingWalkInItem />;
  } else {
    return <ErrorMessage error={hpData.error} />;
  }
};

interface WalkInItemProps {
  hpLocations: HpLocation[];
}

const useCloseWalkIns = (radiusKm: number): WalkInDataResult => {
  const hpData = useWalkInData();
  const location = useContext(LocationContext);
  const myPoint = point(location.lat, location.lng);

  if ("ok" in hpData) {
    return {
      ok: hpData.ok.filter(
        (l) =>
          l.isOpenToday &&
          l.instructionLis.includes(Instruction.walkIn) &&
          distance(point(l.lat, l.lng), myPoint) < radiusKm
      ),
    };
  } else {
    return hpData;
  }
};

const WalkInItem: FunctionComponent<WalkInItemProps> = ({ hpLocations }) => {
  const location = useContext(LocationContext);
  const myPoint = point(location.lat, location.lng);
  const closestWalkIns = sortByAsc(hpLocations, (l) => {
    const a = point(l.lat, l.lng);
    return distance(a, myPoint);
  }).filter(
    (l) => l.isOpenToday && l.instructionLis.includes(Instruction.walkIn)
  );

  const closestWalkIn = closestWalkIns.length ? closestWalkIns[0] : null;
  if (!closestWalkIn) {
    return null;
  }

  const distanceKm = distance(
    point(closestWalkIn.lat, closestWalkIn.lng),
    myPoint
  );
  const walkinPath =
    closestWalkIn != null
      ? `${closestWalkIn.name
          .toLocaleLowerCase()
          .split(" ")
          .join("-")}-${simpleHash(`${closestWalkIn.lat}${closestWalkIn.lng}`)}`
      : null;

  return (
    <WalkInLink
      href={`${VAXXNZ}/${getTabPath(VaxxTab.walkIn)}/${walkinPath}${getSearch(
        location
      )}`}
      target="_blank"
    >
      <StyledWalkInItem>
        <div>
          <h4>{closestWalkIn.name}</h4>
          <p>
            {formatDistanceKm(distanceKm, "en-NZ")} away <Bullet /> Open{" "}
            {closestWalkIn.openTodayHours}
          </p>
        </div>
        <img src="./arrow-right-1.svg" alt="" />
      </StyledWalkInItem>
    </WalkInLink>
  );
};

const LoadingWalkInItem: FunctionComponent = () => (
  <StyledWalkInItem disabled>
    <div>
      <h4>
        <LoadingText len={20} />
      </h4>
      <p>
        <LoadingText len={8} /> <LoadingText len={25} />
      </p>
    </div>
  </StyledWalkInItem>
);
