import { FunctionComponent } from "react";
import styled from "styled-components";
import { SeeMoreLink } from "./SeeMore";

export const StyledSection = styled.section`
  border-top: 1px solid ${(props) => props.theme.colors.separator.primary};
  background: ${(props) => props.theme.colors.section.background};
`;

const StyledSectionHeading = styled.div`
  padding: ${(props) => props.theme.gutter.vertical}
    ${(props) => props.theme.gutter.horizontal};
  border-bottom: 1px solid ${(props) => props.theme.colors.separator.secondary};
  align-items: center;
  display: flex;

  div {
    margin-right: auto;
  }

  h2 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 600;
  }

  a {
    display: inline-block;
  }

  p {
    color: ${(props) => props.theme.colors.text.secondary};
    margin: 0;
    font-size: 0.9rem;
  }

  &:last-child {
    border-bottom: none;
  }
`;

interface SectionProps {
  heading: string;
  subtitle?: string;
  seeMore?: React.ReactNode;
  seeMoreRadiusKm?: number;
}

export const Section: FunctionComponent<SectionProps> = ({
  heading,
  subtitle,
  children,
  seeMore,
  seeMoreRadiusKm,
}) => (
  <StyledSection>
    <StyledSectionHeading>
      <div>
        <h2>{heading}</h2>
        {subtitle && <p>{subtitle}</p>}
      </div>
      {seeMore && (
        <SeeMoreLink radiusKm={seeMoreRadiusKm}>{seeMore}</SeeMoreLink>
      )}
    </StyledSectionHeading>
    {children}
  </StyledSection>
);

export const StyledSectionButton = styled.button`
  font-family: inherit;
  appearance: none;
  outline: none;
  border: none;
  border-radius: 0;
  margin: 0;
  cursor: pointer;
  background: none;
  text-align: left;
  font-size: 1rem;
  padding: ${(props) => props.theme.gutter.vertical}
    ${(props) => props.theme.gutter.horizontal};

  a:hover,
  a:visited,
  a:link,
  a:active {
    color: inherit;
    text-decoration: none;
  }

  h4 {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.text.accent};
  }

  p {
    color: ${(props) => props.theme.colors.text.secondary};
    margin: 0.1rem 0 0.2rem;
    font-size: 1em;
  }

  :disabled {
    cursor: default;
  }

  :not(:disabled):hover {
    background-color: ${(props) => props.theme.colors.hover.background};

    h4,
    h3,
    p {
      color: ${(props) => props.theme.colors.hover.text};
    }
  }
`;
