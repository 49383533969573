import styled from "styled-components";
import { getMetaDataSuburb, getSuburb } from "./location";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ADDRESS_FINDER_API_KEY, DEFAULT_LOCATION } from "../consts";
import React from "react";
import { MyLocation } from "./locationTypes";
import * as microsoftTeams from "@microsoft/teams-js";
import { getConfig } from "../config";
microsoftTeams.initialize();

const StyledLocationPicker = styled.div`
  display: flex;
  background: ${(props) => props.theme.colors.section.background};
  padding: ${(props) => props.theme.gutter.vertical}
    ${(props) => props.theme.gutter.horizontal};
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

const StyledLocationInput = styled.input<{ isDefault: boolean }>`
  border-radius: 0;
  box-sizing: border-box;
  appearance: none;
  outline: none;
  font-family: ${(props) => props.theme.font.family};
  font-size: 1.2em;
  background: none;
  border: 2px solid black;
  color: ${(props) => props.theme.colors.text};
  transition: border-color 0.15s ease;
  padding: 12px 10px;
  flex: 1 0 auto;

  &:focus,
  &:focus-within {
    border-color: ${(props) => props.theme.colors.brand.main};
  }

  &::placeholder {
    color: ${(props) => (props.isDefault ? "#666" : "black")};
  }

  &:disabled {
    filter: grayscale(100%);
    cursor: not-allowed;
  }
`;

const LocationButton = styled.button`
  appearance: none;
  outline: none;
  font-family: ${(props) => props.theme.font.family};
  font-weight: 500;
  font-size: 1.1rem;
  background: ${(props) => props.theme.colors.button.background};
  color: ${(props) => props.theme.colors.button.text};
  border: none;
  transition: background-color 0.15s ease;
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin-left: ${(props) => props.theme.gutter.horizontal};
  flex: 0 1 200px;
  @media screen and (max-width: 500px) {
    flex: initial;
    margin-left: 0;
    margin-top: 1rem;
    min-height: 48px;
  }

  &:disabled {
    filter: grayscale(100%);
    box-shadow: none;
    cursor: not-allowed;
  }

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    background: ${(props) => props.theme.colors.brand.main};
  }

  &:active:not(:disabled) {
    opacity: 0.8;
  }
`;

interface Props {
  setLocation: (location: MyLocation) => void;
}

export const LocationContext =
  React.createContext<MyLocation>(DEFAULT_LOCATION);

export const LocationPicker = function (props: Props) {
  const location = useContext(LocationContext);
  const { setLocation } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [detectFailed, setDetectFailed] = useState<boolean>(false);
  const ref = useRef<HTMLInputElement>();

  const detectMsTeamsLocation =
    async (): Promise<microsoftTeams.location.Location> => {
      return new Promise((resolve, reject) =>
        microsoftTeams.location.getLocation(
          { allowChooseLocation: true },
          (error, loc) => {
            if (error) {
              reject(error);
            } else {
              resolve(loc);
            }
          }
        )
      );
    };
  const detectBrowserLocation = async (): Promise<GeolocationCoordinates> => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position.coords);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };

  const applyLocation = async (loc: {
    latitude: number;
    longitude: number;
  }) => {
    setLocation({
      lat: loc.latitude,
      lng: loc.longitude,
      suburb: await getSuburb(loc.latitude, loc.longitude),
    });
  };

  const debug = (error: any): string => {
    return `${error.message}: ${JSON.stringify(error)}`;
  };

  const fail = () => {
    if (ref.current) {
      ref.current.focus();
    }
    setDetectFailed(true);
  };

  const detectLocation = async () => {
    setDetectFailed(false);
    setLoading(true);
    try {
      const location = await detectMsTeamsLocation();
      applyLocation(location);
    } catch (error1) {
      try {
        const location = await detectBrowserLocation();
        applyLocation(location);
      } catch (error2) {
        console.error("detectLocation error1", error1);
        console.error("detectLocation error2", error2);
        fail();
      }
    }
    setLoading(false);
  };
  const inputRef = useCallback(
    (domNode) => {
      ref.current = domNode;
      if (domNode != null) {
        const widget = new AddressFinder.Widget(
          domNode,
          ADDRESS_FINDER_API_KEY,
          "NZ",
          {
            address_params: {
              post_box: "0",
              max: "7",
            },
            show_locations: true,
            location_params: {
              max: "4",
              region: 0,
            },
          }
        );

        widget.on("result:select", function (fullAddress, metaData) {
          const suburb = getMetaDataSuburb(metaData);
          domNode.value = "";

          setLocation({
            lat: parseFloat(metaData.y),
            lng: parseFloat(metaData.x),
            suburb,
          });
        });
      }
    },
    [setLocation]
  );

  const [detectLocationAllowed, setDetectLocationAllowed] = useState(
    getConfig().detectLocation
  );
  useEffect(() => {
    async function init() {
      const permissionStatus = await navigator.permissions.query({
        name: "geolocation",
      });
      setDetectLocationAllowed(permissionStatus.state !== "denied");
    }
    if (detectLocationAllowed) {
      init();
    }
  }, [detectLocationAllowed]);

  return (
    <StyledLocationPicker>
      <StyledLocationInput
        type="text"
        placeholder={location.suburb}
        isDefault={location.isDefault || false}
        ref={(e) => inputRef(e)}
      />
      {detectLocationAllowed ? (
        <LocationButton onClick={detectLocation}>
          {detectFailed
            ? "Detect Failed"
            : loading
            ? "Loading..."
            : "Detect Location"}
        </LocationButton>
      ) : null}
    </StyledLocationPicker>
  );
};
