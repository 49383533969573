import { useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { CalendarSection } from "../calendar/CalendarSection";
import { DEFAULT_LOCATION } from "../consts";
import { Header } from "./Header";
import { LocationContext, LocationPicker } from "../location/LocationPicker";
import { theme } from "../theme";
import { WalkInSection } from "../walk-in/WalkInSection";
import { MyLocation } from "../location/locationTypes";

const StyledApp = styled.div`
  margin: 0 auto;
  box-sizing: border-box;
  max-height: ${(props) => props.theme.layout.maxHeight};
`;

const BodyContent = styled.div`
  background: ${(props) => props.theme.colors.background};
  border-bottom: 1px solid ${(props) => props.theme.colors.separator.primary};
  border-left: 1px solid ${(props) => props.theme.colors.separator.primary};
  border-right: 1px solid ${(props) => props.theme.colors.separator.primary};
`;

const Attributions = styled.p`
  font-size: 12px;
  color: #999;
  margin: 5px 5px 0;

  a {
    color: #666;
  }
`;

function App() {
  const [location, setLocation] = useState<MyLocation>(DEFAULT_LOCATION);

  return (
    <ThemeProvider theme={theme}>
      <StyledApp>
        <LocationContext.Provider value={location}>
          <Header />
          <BodyContent>
            <LocationPicker setLocation={setLocation} />
            <CalendarSection />
            <WalkInSection />
          </BodyContent>
          <Attributions>
            This is not an official government tool.{" "}
            <a href="https://docs.vaxx.nz/" target="_top">
              Add vaxx.nz to your website.
            </a>{" "}
            Address lookup kindly provided by{" "}
            <a
              href="https://addressfinder.nz/"
              target="_blank"
              rel="noreferrer"
            >
              AddressFinder
            </a>
            .
          </Attributions>
        </LocationContext.Provider>
      </StyledApp>
    </ThemeProvider>
  );
}

export default App;
