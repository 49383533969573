import { BmvAvailability, BmvLocation } from "./bmvTypes";
import { HpLocation } from "./hpTypes";
import { memoizeOnce } from "../utils/memoize";

export const getBmvLocations = memoizeOnce(async () => {
  const res = await fetch(
    "https://raw.githubusercontent.com/CovidEngine/vaxxnzlocations/main/uniqLocations.json"
  );
  const data: BmvLocation[] = await res.json();
  return data;
});

export const getBmvAvailability = async (extId: string) => {
  const res = await fetch(
    `https://raw.githubusercontent.com/CovidEngine/vaxxnzlocations/main/availability/${extId}.json`
  );
  const data: BmvAvailability = await res.json();
  return data;
};

export const getHpLocations = async () => {
  const res = await fetch(
    "https://raw.githubusercontent.com/CovidEngine/vaxxnzlocations/main/healthpointLocations.json"
  );
  const data: HpLocation[] = await res.json();
  return data;
};
