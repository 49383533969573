import { useState, useEffect } from "react";
import { getHpLocations } from "../data/data";
import { HpLocation } from "../data/hpTypes";

export type WalkInDataResult =
  | { ok: HpLocation[] }
  | { error: Error }
  | { loading: true };

export const useWalkInData = (): WalkInDataResult => {
  const [hpError, setHpError] = useState<Error | null>(null);

  const [hpLocations, setHpLocations] = useState<HpLocation[] | null>(null);

  useEffect(() => {
    async function getWalkIns() {
      setHpLocations(null);
      setHpError(null);
      try {
        setHpLocations(await getHpLocations());
      } catch (error) {
        setHpError(error as Error);
      }
    }
    getWalkIns();
  }, []);

  if (hpError) {
    return { error: hpError };
  } else if (hpLocations) {
    return { ok: hpLocations };
  } else {
    return { loading: true };
  }
};
