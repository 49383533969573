interface Config {
  detectLocation: boolean;
}

const defaultConfig: Config = {
  detectLocation: true,
};

const config: Record<string, Config> = {
  oembed: {
    detectLocation: true,
  },
  teams: {
    detectLocation: false,
  },
};

function getSpecificConfig(key: string): Config {
  return config[key] || defaultConfig;
}

function getKey() {
  let key: string = "default";
  const sp = new URL(window.location.toString()).searchParams;
  const provider = sp.get("provider");
  if (window.location.hostname === "teams.vaxx.nz") {
    key = "teams";
  }
  if (provider === "oembed") {
    key = "oembed";
  }
  return key;
}

export function getConfig(): Config {
  return getSpecificConfig(getKey());
}
