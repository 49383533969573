import React, { FunctionComponent, useContext } from "react";
import styled, { css } from "styled-components";
import { VAXXNZ } from "../consts";
import { LocationContext } from "../location/LocationPicker";
import { MyLocation } from "../location/locationTypes";

const StyledSeeMoreLink = styled.a<SeeMoreLinkProps>`
  ${(props) =>
    !props.noStyle
      ? css`
          color: ${(props) => props.theme.colors.text.accent};
          text-decoration: none;
          text-align: right;

          &:hover {
            text-decoration: underline;
            color: ${(props) => props.theme.colors.hover.text};
          }
        `
      : css`
          text-decoration: none;
        `}
`;

interface SeeMoreLinkProps {
  radiusKm?: number;
  noStyle?: boolean;
  tab?: VaxxTab;
}

export enum VaxxTab {
  bookings,
  walkIn,
}

export const SeeMoreLink: FunctionComponent<SeeMoreLinkProps> = ({
  children,
  radiusKm,
  noStyle,
  tab,
}) => {
  const location = useContext(LocationContext);
  return (
    <StyledSeeMoreLink
      noStyle={noStyle}
      href={vaxxUrl(location, radiusKm, tab)}
      target="_blank"
    >
      {children}
    </StyledSeeMoreLink>
  );
};

const vaxxUrl = (location?: MyLocation, radiusKm?: number, tab?: VaxxTab) => {
  return `${VAXXNZ}/${getTabPath(tab)}${getSearch(location, radiusKm)}`;
};

const getRadiusQuery = (radiusKm?: number) => {
  if (radiusKm) {
    return {
      radius: `${radiusKm}`,
    };
  } else {
    return {
      radius: "10closest",
    };
  }
};

export const getTabPath = (tab?: VaxxTab) => {
  if (tab === VaxxTab.walkIn) {
    return "locations";
  } else {
    return "";
  }
};

const getUtm = () => {
  // utm_medium: vaxxwidget
  // utm_source: widgetdemovaxxnz/wwwgeorgefmconz
  // utm_campaign: oembed/teams/iframe/custom
  const sp = new URL(window.location.toString()).searchParams;
  const hostname = window.location.hostname;

  const provider =
    sp.get("provider") ?? (hostname === "teams.vaxx.nz" ? "teams" : "iframe");
  const utm_campaign_original = sp.get("utm_campaign");
  const utm_campaign = utm_campaign_original ?? provider;

  const utm_medium = "vaxxwidget";
  const utm_source = document.referrer
    ? new URL(document.referrer).host.replace(/\./g, "").replace(/-/g, "")
    : undefined;

  return {
    utm_medium,
    ...(utm_source ? { utm_source } : {}),
    utm_campaign,
  };
};

function searchParamsToString(params: { [key: string]: string }) {
  const sp = new URLSearchParams(params).toString();
  return sp ? `?${sp}` : "";
}

export const getSearch = (location?: MyLocation, radiusKm?: number) => {
  if (!location || location.isDefault) {
    const sp = {
      ...getRadiusQuery(radiusKm),
      ...getUtm(),
    };
    return searchParamsToString(sp);
  } else {
    const sp = {
      lat: `${location.lat}`,
      lng: `${location.lng}`,
      placeName: location.suburb,
      ...getRadiusQuery(radiusKm),
      ...getUtm(),
    };
    return searchParamsToString(sp);
  }
};
