const BRAND = "#0077c1";

export const theme = {
  layout: {
    maxHeight: "635px",
  },

  colors: {
    background: "#efefef",

    text: {
      accent: BRAND,
      primary: "black",
      secondary: "#444",
      tertiary: "#666",
    },
    hover: {
      background: "rgb(0, 119, 193, 0.04)", // BRAND @ 4% opacity
      text: "#045d8a",
    },

    section: {
      background: "white",
    },

    separator: {
      primary: "#d3d3d3",
      secondary: "#e8e8e8",
    },

    brand: {
      main: BRAND,
      gradient: {
        start: BRAND,
        end: "#059295",
      },
    },

    button: {
      background: "black",
      text: "white",
    },
  },

  font: {
    family: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
  },

  gutter: {
    horizontal: "1rem",
    vertical: "0.8rem",
  },
};
