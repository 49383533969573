import { useContext, useEffect, useState } from "react";
import { getBmvAvailability, getBmvLocations } from "../data/data";
import { sortByAsc } from "../utils/arrayUtils";
import distance from "@turf/distance";
import { point } from "../location/locationUtils";
import { BmvAvailability, SlotWithAvailability } from "../data/bmvTypes";
import { format, isAfter, isToday, parse } from "date-fns";
import { CalendarDay, CalendarDayLocation } from "../data/calendarTypes";
import { LocationContext } from "../location/LocationPicker";

export type CalendarDataResult =
  | { ok: CalendarDay[] }
  | { error: Error }
  | { loading: true };

const filterOldSlots = (
  slots: SlotWithAvailability[],
  isTodayDate: boolean
) => {
  if (isTodayDate) {
    return slots.filter(({ localStartTime }) => {
      const startTime = parse(localStartTime, "HH:mm:ss", new Date());
      return isAfter(startTime, new Date());
    });
  }
  return slots;
};

export const useCalendarDays = (): CalendarDataResult => {
  const location = useContext(LocationContext);
  const [calendarDays, setCalendarDays] = useState<CalendarDay[] | null>(null); // null while loading or error
  const [calendarError, setCalendarError] = useState<Error | null>(null);

  useEffect(() => {
    async function getCalendarDays() {
      setCalendarDays(null);
      setCalendarError(null);
      try {
        const bmvLocations = await getBmvLocations();
        const myPoint = point(location.lat, location.lng);
        const closestBmvLocations = sortByAsc(bmvLocations, (l) => {
          const a = point(l.location.lat, l.location.lng);
          return distance(a, myPoint);
        });
        const tenClosestBmvLocations = closestBmvLocations.slice(0, 10);
        const bmvAvailabilityAndLocations = await Promise.all(
          tenClosestBmvLocations.map(async (location) => {
            let bmvAvailability: BmvAvailability | undefined = undefined;
            try {
              bmvAvailability = await getBmvAvailability(location.extId);
            } catch (error) {
              console.error("getMyCalendar error", error);
            }
            return {
              ...bmvAvailability,
              location,
            };
          })
        );
        const today = new Date();
        const calendarDays: CalendarDay[] = [];
        for (let i = 0; i < 60; i++) {
          // should stop at 8 days, circuit breaker after 60 days
          const date = new Date().setDate(today.getDate() + i);
          const dateStr = format(date, "yyyy-MM-dd");
          const calendayDayLocations: CalendarDayLocation[] = [];
          for (let j = 0; j < bmvAvailabilityAndLocations.length; j++) {
            const bmvAvailabilityDatesAndLocation =
              bmvAvailabilityAndLocations[j];
            const { location, availabilityDates } =
              bmvAvailabilityDatesAndLocation;
            const slots = availabilityDates ? availabilityDates[dateStr] : [];
            if (slots) {
              const isTodayDate = isToday(date);
              const filteredSlots = filterOldSlots(slots, isTodayDate);
              if (filteredSlots.length > 0) {
                calendayDayLocations.push({ location, slots: filteredSlots });
              }
            }
          }
          if (calendayDayLocations.length > 0) {
            calendarDays.push({
              dateStr,
              calendayDayLocations,
            });
          }
          if (calendarDays.length >= 8) {
            break;
          }
        }
        setCalendarDays(calendarDays);
      } catch (error) {
        setCalendarError(error as Error);
      }
    }
    getCalendarDays();
  }, [location.lat, location.lng]);

  if (calendarError) {
    return { error: calendarError };
  } else if (calendarDays) {
    return { ok: calendarDays };
  } else {
    return { loading: true };
  }
};
