import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { SeeMoreLink } from "./SeeMore";

interface ErrorMessageProps {
  error: Error;
}

const StyledErrorMessage = styled.div`
  margin: 2rem;
  text-align: center;

  h4 {
    font-weight: 500;
    margin: 0;
    font-size: 1rem;
  }

  p {
    margin: 0.7rem 0 0;
    font-size: 0.8rem;
  }
`;

export const ErrorMessage: FunctionComponent<ErrorMessageProps> = ({
  error,
}) => {
  return (
    <StyledErrorMessage>
      <h4>
        Something failed while loading, try visiting{" "}
        <SeeMoreLink>vaxx.nz</SeeMoreLink>
      </h4>
      <p>{error.message}</p>
    </StyledErrorMessage>
  );
};
