import { differenceInCalendarDays, parse } from "date-fns";
import React, { FunctionComponent, useContext } from "react";
import styled from "styled-components";
import { StyledSectionButton } from "../layout/Section";
import { VAXXNZ } from "../consts";
import { getSearch } from "../layout/SeeMore";
import { LocationContext } from "../location/LocationPicker";
import { CalendarDataResult } from "./CalendarData";
import { LoadingText } from "../layout/Loading";
import { ErrorMessage } from "../layout/ErrorMessage";

interface CalendarDaysProps {
  calendarData: CalendarDataResult;
}

const StyledCalendarDays = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  a:nth-child(4) button {
    border-right: none;
  }
  a:nth-child(8) button {
    border-right: none;
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
    a:nth-child(2) button {
      border-right: none;
    }
  }

  @media screen and (max-width: 500px) {
    a:nth-child(n + 5) {
      display: none;
    }
  }
  @media screen and (max-width: 400px) {
    a:nth-child(n + 3) {
      display: none;
    }
  }
`;

function sum(array: number[]) {
  return array.reduce((a, b) => a + b, 0);
}

export const CalendarDays: FunctionComponent<CalendarDaysProps> = ({
  calendarData,
}) => {
  if ("ok" in calendarData) {
    return (
      <StyledCalendarDays>
        {calendarData.ok.map((day) => {
          return (
            <CalendarDayBlock
              key={day.dateStr}
              dateStr={day.dateStr}
              count={sum(
                day.calendayDayLocations.map(({ slots }) => slots.length)
              )}
            />
          );
        })}
      </StyledCalendarDays>
    );
  } else if ("loading" in calendarData) {
    return (
      <StyledCalendarDays>
        {Array(window.outerWidth < 400 ? 2 : (window.outerWidth < 500 ? 4 : 8))
          .fill(null)
          .map((_, i) => (
            <LoadingCalendarDayBlock key={i} />
          ))}
      </StyledCalendarDays>
    );
  } else {
    return <ErrorMessage error={calendarData.error} />;
  }
};

interface CalendarDayProps {
  dateStr: string;
  count: number;
}

const StyledCalendarDay = styled(StyledSectionButton)`
  border-bottom: 1px solid ${(props) => props.theme.colors.separator.secondary};
  border-right: 1px solid ${(props) => props.theme.colors.separator.secondary};
  width: 100%;

  h4 + p {
    text-transform: capitalize;
  }

  p:last-child {
    margin-top: 0.9em;
    font-size: 0.9rem;
    color: ${(props) => props.theme.colors.text.tertiary};
  }
`;

const LoadingCalendarDayBlock: FunctionComponent = () => (
  <StyledCalendarDay disabled>
    <h4>
      <LoadingText len={6} />
    </h4>
    <p>
      <LoadingText />
    </p>
    {/* <br /> */}
    <p>
      <LoadingText len={8} />
    </p>
  </StyledCalendarDay>
);
const StyledA = styled.a``

const CalendarDayBlock: FunctionComponent<CalendarDayProps> = ({
  dateStr,
  count,
}) => {
  const date = parse(dateStr, "yyyy-MM-dd", new Date());

  const location = useContext(LocationContext);
  const daysDiff = differenceInCalendarDays(date, new Date());

  const relativeFormatter = new Intl.RelativeTimeFormat(undefined, {
    numeric: "auto",
  });

  const relativeDate =
    daysDiff < 2
      ? relativeFormatter.format(daysDiff, "days")
      : date.toLocaleDateString("en-NZ", {
          weekday: "long",
        });
  return (
    <StyledA
      target="_blank"
      href={`${VAXXNZ}/bookings/${dateStr}${getSearch(location)}`}
    >
      <StyledCalendarDay>
        <h4>
          {date.toLocaleDateString("en-NZ", {
            day: "numeric",
            month: "short",
          })}
        </h4>
        <p>{relativeDate}</p>
        <p>{count} available</p>
      </StyledCalendarDay>
    </StyledA>
  );
};
