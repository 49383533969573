import { FunctionComponent } from "react";
import styled from "styled-components";
import { SeeMoreLink } from "./SeeMore";

const StyledHeader = styled.header`
  background: ${(props) => props.theme.colors.brand.main};
  background: linear-gradient(
    90deg,
    ${(props) => props.theme.colors.brand.gradient.start},
    ${(props) => props.theme.colors.brand.gradient.end}
  );
  color: white;
  font-weight: bold;
  padding: 1.25rem ${(props) => props.theme.gutter.horizontal};
  margin: 0;
  display: flex;
  flex-direction: table-row;
  justify-content: space-between;
  align-items: center;

  h1,
  p {
    margin: 0;
    font-size: 1.6rem;
  }

  a {
    font-size: 1rem;
    font-weight: 500;
    color: white;
    text-decoration: none;

    &:hover {
      color: white;
      text-decoration: none;
    }
  }

  @media screen and (min-width: 501px) {
    a {
      div {
        margin-bottom: -0.2em;
        font-weight: 400;
        opacity: 0.8;
        font-size: 0.7em;
      }
    }
  }

  @media screen and (max-width: 500px) {
    display: block;
    a {
      opacity: 0.8;
      font-size: 0.9rem;
      display: block;
      text-align: left;
      div {
        display: inline-block;
      }
    }
  }
`;

const DesktopOnly = styled.div`
  @media screen and (max-width: 500px) {
    display: none;
  }
`

export const Header: FunctionComponent = () => (
  <StyledHeader>
    <h1>Find a COVID-19 Vaccination</h1>
    <DesktopOnly>
      <SeeMoreLink>
        <div>Powered by</div> vaxx.nz
      </SeeMoreLink>
    </DesktopOnly>
  </StyledHeader>
);
