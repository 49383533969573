import styled, { keyframes } from "styled-components";

export const LOADING_ANIMATION = keyframes`
0%, 100% {
  opacity: 0.2;
}

50% {
  opacity: 0.08;
}
`;

export const LOADING_ANIMATION_TILE = keyframes`
0%, 100% {
  opacity: 0.5;
}

50% {
  opacity: 0.2;
}
`;

export const DEFAULT_LOADING_LENGTH = 10;

export interface LoadingLengthProp {
  // null = fit to 100% of the container,undefined = default width
  len?: number | null;
}

export const LoadingText = styled.span<LoadingLengthProp>`
  display: inline-block;
  width: ${(props) =>
    props.len !== null ? `${props.len || DEFAULT_LOADING_LENGTH}ch` : "100%"};
  margin-top: 0.25em;
  background: currentcolor;
  height: 0.7em;
  border-radius: 0.4em;
  opacity: 0.6;
  animation: ${LOADING_ANIMATION} 1.2s infinite linear;
  filter: saturate(0.5);
`;
