import React, { FunctionComponent } from "react";
import { Section } from "../layout/Section";
import { CalendarDays } from "./CalendarDays";
import styled from "styled-components";
import { SeeMoreLink } from "../layout/SeeMore";
import { useCalendarDays } from "./CalendarData";
import { LoadingText } from "../layout/Loading";

const SeeMore = styled.div`
  text-align: center;
  width: 100%;
  display: block;
  padding: ${(props) => props.theme.gutter.vertical};
  box-sizing: border-box;

  :hover {
    background-color: ${(props) => props.theme.colors.hover.background};
  }
`;

interface CalendarSectionProps {}

export const CalendarSection: FunctionComponent<CalendarSectionProps> = () => {
  const result = useCalendarDays();
  return (
    <Section heading="Available Booking Slots">
      <CalendarDays calendarData={result} />
      {!("error" in result) && (
        <SeeMoreLink>
          <SeeMore>
            {"loading" in result ? (
              <LoadingText len={14} />
            ) : (
              "See more dates..."
            )}
          </SeeMore>
        </SeeMoreLink>
      )}
    </Section>
  );
};
